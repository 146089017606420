import "./App.css";

function App() {
  return (
    <>
      <main>
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4 sm:p-6 md:p-20 text-center">
          {/* Logo */}
          <div className="md:absolute md:top-2 md:left-2 sm:top-4 sm:left-4">
            <img src="logo.png" alt="Logo" className="h-24 w-auto" />
          </div>

          {/* Content */}
          <div>
            <h1 className="title text-xl sm:text-2xl md:text-2xl lg:text-3xl">
              VistaGrow fusionne et devient VistaGroup
            </h1>

            {/* Subtitle */}
            <h2 className="subtitle mt-2 text-lg sm:text-xl md:text-xl lg:text-2xl">
              <b>Vistagroup</b> est un groupement de 3 agences spécialisées dans
              leur domaine de prédilection et parmi les meilleurs dans leurs
              spécialités respectives:
            </h2>

            {/* Paragraph */}
            <ul className="list-disc list-inside text-start mt-4 space-y-2">
              <li className="text text-sm sm:text-base md:text-lg lg:text-xl">
                <b>Caap</b> est une agence de développement créée en 2014.
                L’équipe d’ingénieurs est en charge de la réalisation
                d’applications mobiles et web, ainsi que de plateformes
                personnalisées (CRM / ERP)
              </li>
              <li className="text text-sm sm:text-base md:text-lg lg:text-xl">
                <b>VistaGrow</b> crée en 2016 est l’allié idéal pour votre
                communication et stratégie web. Partenaire de Google ads, Elle
                s’occupe du design de vos supports de communication ainsi que de
                creation, la réalisation et le suivi de vos campagnes marketing
              </li>
              <li className="text text-sm sm:text-base md:text-lg lg:text-xl">
                <b>Trust</b>, la petite dernière, crée en 2021, spécialisée dans
                la cyber sécurité, opère sur plusieurs secteurs tels que les
                banques et les opérateurs.
              </li>
            </ul>

            <p className="text text-start mt-4 text-sm sm:text-base md:text-lg lg:text-xl">
              Nous disposons de plusieurs certifications
              <br />
              Propres à chaque domaine d’activité.
              <br />
              Nos références opérant dans différents secteurs d’activité vous
              donneront une garantie supplémentaire sur notre professionnalisme.
            </p>

            <div className="flex flex-col md:flex-row align-center justify-center sm:px-12 md:px-24 lg:px-24">
              <div className="relative group m-auto w-64 h-64 sm:w-64 sm:h-64 md:w-72 md:h-72 lg:w-84 lg:h-84">
                <img
                  src="icone-de-aplication-mobile.png"
                  alt="Icon 1"
                  className="absolute inset-0 object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300 m-auto"
                />
                <img
                  src="bouton-aplication-mobile.png"
                  alt="Icon 1"
                  className="absolute inset-0 m-auto z-10 w-[40%] h-[40%]"
                />
              </div>

              <div className="relative group m-auto w-64 h-64 sm:w-64 sm:h-64 md:w-72 md:h-72 lg:w-84 lg:h-84">
                <img
                  src="icones-cyber-securite.png"
                  alt="Icon 2"
                  className="absolute inset-0 object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300 m-auto"
                />
                <img
                  src="bouton-digitale-marketing.png"
                  alt="Icon 2"
                  className="absolute inset-0 m-auto z-10 w-[40%] h-[40%]"
                />
              </div>

              <div className="relative group m-auto w-64 h-64 sm:w-64 sm:h-64 md:w-72 md:h-72 lg:w-84 lg:h-84">
                <img
                  src="icones-digitale-marketing.png"
                  alt="Icon 3"
                  className="absolute inset-0 object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300 m-auto"
                />
                <img
                  src="bouton-cyber-securite.png"
                  alt="Icon 3"
                  className="absolute inset-0 m-auto z-10 w-[40%] h-[40%]"
                />
              </div>
            </div>
          </div>

          {/* Maintenance Icon */}
          <img
            src="bas.png"
            alt="Maintenance Icon"
            className="h-48 w-48 sm:h-64 sm:w-64 md:h-72 md:w-72 lg:h-96 lg:w-96 mt-8"
          />
        </div>
      </main>
    </>
  );
}

export default App;
